import * as Sentry from '@sentry/vue';
import { storeToRefs } from 'pinia';
import config from '@/config';
import { UnpackedError } from '@/errors';
import useAuthStore from '@/stores/auth';
import pkg from '../package.json';
export default class SentryApi {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() { }
    static init(app, router) {
        try {
            SentryApi._storeRefs = storeToRefs(useAuthStore());
            Sentry.init({
                app,
                dsn: config.SENTRY_UI_DSN,
                environment: config.STAGE,
                replaysSessionSampleRate: config.SENTRY_REPLAY_SESSIONS,
                replaysOnErrorSampleRate: config.SENTRY_ERROR_REPLAY_SESSIONS,
                beforeSend(event) {
                    if (SentryApi.isDevMode()) {
                        return null;
                    }
                    const { username, accountId, role, userId } = SentryApi._storeRefs;
                    // add tags
                    event.tags = {
                        userId: userId.value,
                        accountId: accountId.value,
                        feature: config.FEATURE,
                        username: username.value,
                        role: role.value,
                    };
                    return event;
                },
                release: `${config.SENTRY_PROJECT}@${pkg.version}`,
                integrations: [
                    Sentry.browserTracingIntegration({ router }),
                    Sentry.replayIntegration(),
                ],
                attachStacktrace: true,
                autoSessionTracking: true,
                maxBreadcrumbs: config.SENTRY_MAX_BREADCRUMBS,
                tracesSampleRate: config.SENTRY_TRACES_SAMPLE_RATE,
            });
        }
        catch (e) {
            console.error(`Can not initiate Sentry due to: ${e}`);
        }
    }
    static captureException(err) {
        const unpackedError = new UnpackedError(err);
        if (!SentryApi.isDevMode()) {
            Sentry.captureException(unpackedError);
        }
        console.error(unpackedError);
    }
    static isDevMode() {
        return process.env.NODE_ENV === 'development';
    }
}
